.push-up-fade-out {
    animation: pushUpFadeOut 1s ease-in-out forwards;
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in-out forwards;
  }

  @keyframes pushUpFadeOut {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-50%);
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  